define("additive-account/routes/instances", ["exports", "additive-account/config/environment", "@ember/routing/route", "@ember/object", "@ember/service", "@userback/widget", "@additive-apps/auth/mixins/auth-route-mixin", "additive-account/utils/constants"], function (_exports, _environment, _route, _object, _service, _widget, _authRouteMixin, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const GLOBAL_USER_STATE_OBJECT = {
    user: null
  };
  var _default = _exports.default = _route.default.extend(_authRouteMixin.default, {
    authenticatedFetch: (0, _service.inject)(),
    uiState: (0, _service.inject)(),
    uiPaths: (0, _service.inject)(),
    session: (0, _service.inject)(),
    uiLocale: (0, _service.inject)(),
    currentUser: (0, _service.inject)(),
    redirectUri: `${_environment.default.APP.baseHost}${_environment.default.APP.redirectRoute}`,
    /**
     * The global user state
     *
     * @property _globalUserState
     * @type {Object}
     * @private
     */
    _globalUserState: null,
    init() {
      this._super(...arguments);
      (0, _object.set)(this, '_globalUserState', Object.assign({}, GLOBAL_USER_STATE_OBJECT));
      this.uiState.register(_constants.GLOBAL_USER_STATE_KEY, this._globalUserState);
    },
    async afterModel(model, transition) {
      this._super(...arguments);
      if (transition.targetName === 'instances.index') {
        this.transitionTo('instances.me');
      } else {
        const globalUserState = this._globalUserState;
        const user = globalUserState && globalUserState.user;

        // If we already have an user, don't fetch twice
        if (user) {
          return;
        }
        const url = this.uiPaths.pathsByRouteName('instances.me').api().url;
        try {
          var _transition$to, _me$user, _me$user3, _me$user4;
          const data = await this.authenticatedFetch.fetch(url);
          if (!data || !data.ok) {
            // This fetch could occure before the org-user call, so we have to check for 401 too
            if (data.status === 401) {
              this.session.invalidate();
            }
            throw new Error();
          }
          const me = await data.json();
          if (!me || !me.user) {
            throw new Error();
          }
          const {
            email,
            emailVerified,
            organizationCount,
            language
          } = me.user;
          await this.uiLocale.setLocale(language || 'de');
          if (!emailVerified) {
            this.transitionTo('confirm', {
              queryParams: {
                signup_email: email
              }
            });
          } else if (organizationCount === 0 && !((_transition$to = transition.to) !== null && _transition$to !== void 0 && (_transition$to = _transition$to.queryParams) !== null && _transition$to !== void 0 && _transition$to.token)) {
            /**
             * With orgCount === 0 and no token queryParam the user will be redirected to /create
             */
            this.transitionTo('instances.create');
          }

          /**
           * With token queryparam set and orgCount === 0 the user has an pending invitation to an org.
           * The accept-request will be made at instances.me route, so let the user transition to that route
           * to complete invitation flow
           */
          (me === null || me === void 0 ? void 0 : me.user) && (0, _object.set)(globalUserState, 'user', me.user);
          if (me !== null && me !== void 0 && (_me$user = me.user) !== null && _me$user !== void 0 && _me$user.isAdditive) {
            var _me$user2;
            const widgetKey = _environment.default.APP.productlaneWidgetKey;

            // Ensure the script is loaded if not already
            if (!document.getElementById('productlane-script')) {
              const script = document.createElement('script');
              script.id = 'productlane-script';
              script.async = true;
              script.defer = true;
              script.crossOrigin = 'anonymous';
              script.src = 'https://widget.productlane.com/latest.productlane-widget.min.js';
              document.body.appendChild(script);
            }
            (w => {
              const P = w.Productlane = {
                queue: {}
              };
              ['set', 'open', 'close', 'toggle', 'on', 'off', 'init'].forEach(m => {
                P[m] = (n => function () {
                  P.queue[n] = {
                    args: arguments
                  };
                })(m);
              });
            })(window);
            window.Productlane.init({
              widgetKey,
              theme: {
                '--text-color-primary-dark': '#00172F',
                '--text-color-secondary-dark': '#8F9EA4',
                '--text-color-highlight-dark': '#4d76f1',
                '--background-color-primary-dark': '#ffffff',
                '--background-color-secondary-dark': '#8f9ea41a',
                '--border-color-primary-dark': '#061C2D1A',
                '--text-color-primary': '#00172F',
                '--text-color-secondary': '#8F9EA4',
                '--text-color-highlight': '#4d76f1',
                '--background-color-primary': '#ffffff',
                '--background-color-secondary': '#8f9ea41a',
                '--border-color-primary': '#061C2D1A'
              },
              user: {
                email: me === null || me === void 0 || (_me$user2 = me.user) === null || _me$user2 === void 0 ? void 0 : _me$user2.email
              }
            });
            window.Productlane.on('loaded', () => {
              var _document$getElementB;
              const widget = (_document$getElementB = document.getElementById('ProductlaneWidget-MainWidget')) === null || _document$getElementB === void 0 ? void 0 : _document$getElementB.parentElement;
              widget.style.zIndex = '9999999999';
              const hasPermanentNavdrawer = document.querySelector('.ui-navigation-drawer--permanent');
              if (hasPermanentNavdrawer) {
                widget.style.left = '284px';
              } else {
                widget.style.left = '0px';
              }
            });
          }
          if (me !== null && me !== void 0 && (_me$user3 = me.user) !== null && _me$user3 !== void 0 && _me$user3.isAdditive || me !== null && me !== void 0 && (_me$user4 = me.user) !== null && _me$user4 !== void 0 && _me$user4.isPartner) {
            (0, _widget.default)(me.user.isPartner ? _environment.default.APP.userbackAccessTokenPartner : _environment.default.APP.userbackAccessToken, {
              email: me.user.email,
              name: me.user.fullName,
              categories: 'Account'
            });
          }
          if (typeof Sprig === 'function' && me !== null && me !== void 0 && me.user) {
            /* eslint-disable */
            Sprig('setUserId', me.user.id);
            Sprig('setEmail', me.user.email);
            /* eslint-enable */
          }
        } catch (error) {
          // On error reset user
          (0, _object.set)(globalUserState, 'user', null);
        }
      }
    }
  });
});